import * as React from "react";
import styled from "styled-components";
import { Flex } from "@quandoo-dev/cookbook/lib/layout";
import { Body as _Body } from "@quandoo-dev/cookbook/lib/typography";

import Link from "../link";
import CaretIcon from "../../assets/caret-right.inline.svg";

import { Helmet } from "react-helmet";

const mockCrumbs = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Guide",
    to: "/",
  },
  {
    name: `Pasta's calling - The best Italian restaurants`,
    to: "/",
  },
];

const Wrapper = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 30px;
`;

export interface Crumb {
  name: string;
  to?: string;
  item?: string;
}

interface BreadcrumbsProps {
  crumbs: Crumb[];
}

const Body = styled(_Body)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 50vw;
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
  const schemaCrumbs = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: crumbs.map(({ name, item }, index) => {
          return item
            ? {
                "@type": "ListItem",
                position: index + 1,
                name: name,
                "@item": item,
              }
            : ``;
        }),
      })}
    </script>
  );

  return (
    <Wrapper>
      <Helmet>{schemaCrumbs}</Helmet>
      {crumbs.map(({ name, to }, index) => (
        <Flex as="li" key={name} display="flex" alignItems="center" mr="xs">
          {Boolean(to) ? (
            <Link to={to}>
              <Body color="secondary" size="xs" mr="xs">
                {name}
              </Body>
            </Link>
          ) : (
            <Body color="secondary" size="xs" mr="xs">
              {name}
            </Body>
          )}
          {index !== crumbs.length - 1 && <CaretIcon />}
        </Flex>
      ))}
    </Wrapper>
  );
};

export default Breadcrumbs;

const ids = {
  de: '019011a0-d647-7c31-9f30-0cd4aed860d1',
  it: '019011a8-0284-7c0a-813b-0a4314e10312',
  sg: '019011a8-80f3-78b8-a9e7-661a8fa5f779',
  uk: '019011a8-b9c1-7f9a-83b6-04cfbc1d7fe2',
}

const getOneTrustId = (country: string) => {
  return ids[country];
};

export { getOneTrustId };